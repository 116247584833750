import React from "react"
import { FaLinkedin } from "react-icons/fa"
import { FaTwitterSquare } from "react-icons/fa"
import { FaViadeoSquare } from "react-icons/fa"
import { FaEnvelopeSquare } from "react-icons/fa"
import { FaGithubSquare } from "react-icons/fa"


class Footer extends React.Component {
    constructor(props) {
        super(props);
      this.state = {
        error: null,
        isLoaded: false
      };
    }

    render(){
        return(
          <div className="footer">
          <div>Retrouvez moi sur les réseaux professionnels</div>
          <a href="https://www.linkedin.com/in/j%C3%A9r%C3%A9mie-binet-043b8a7a"><FaLinkedin /></a>
          <a href="https://viadeo.journaldunet.com/p/jeremie-binet-5891943"><FaViadeoSquare /></a>
          <a href="mailto:jeremie.binet@gmail.com"><FaEnvelopeSquare /></a>
          <a href="https://github.com/Inferno1290"><FaGithubSquare /></a>
          </div>
        );
    }
}

export default Footer;